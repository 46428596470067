import { render, staticRenderFns } from "./ka-sign-up-page.vue?vue&type=template&id=5724d6ca&scoped=true&"
import script from "./ka-sign-up-page.vue?vue&type=script&lang=js&"
export * from "./ka-sign-up-page.vue?vue&type=script&lang=js&"
import style0 from "./ka-sign-up-page.vue?vue&type=style&index=0&id=5724d6ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5724d6ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KInput from '@web/components/core/k-input'
import KPassword from '@web/components/core/k-password'
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {KButton,KInput,KPassword,VCol,VExpandTransition,VRow})
