<template>
    <ka-auth-page>
        <div class="grid">
            <form @submit.prevent="submit" class="form">
                <h1 class="tw-heading-1">{{ $t("auth.titles.create_account") }}</h1>

                <ka-sign-in-with-providers signUp />

                <div class="form-inputs">
                    <v-row>
                        <v-col>
                            <k-input
                                v-model="email"
                                type="email"
                                :title="$t('global.inputs.email')"
                                :invalid="submitted && !isValidField('email')"
                                name="username"
                                autocomplete="username"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <k-password
                                v-model="password"
                                :placeholder="$t('global.form_rules.length_6')"
                                :title="$t('global.inputs.password')"
                                :invalid="submitted && !isValidField('password')"
                                name="new-password"
                                autocomplete="new-password"
                            />
                        </v-col>
                    </v-row>

                    <v-row class="mt-2">
                        <v-col>
                            <v-expand-transition>
                                <div v-show="signUpStatus.error" class="mb-8 form-error-message">
                                    <p>{{ $t(signUpStatus.error) }}</p>
                                </div>
                            </v-expand-transition>

                            <v-expand-transition>
                                <div v-show="errorMessage" class="mb-8 form-error-message">
                                    <p>{{ $t(errorMessage) }}</p>
                                </div>
                            </v-expand-transition>

                            <div>
                                <k-button
                                    color="primary"
                                    class="tw-w-full sm:tw-w-auto"
                                    :loading="signUpStatus.isLoading"
                                    :success="signUpStatus.isSuccess"
                                    :disabled="signUpStatus.isLoading"
                                    type="submit"
                                >
                                    {{ $t("auth.actions.create_account") }}
                                </k-button>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="mt-2">
                        <v-col>
                            <p>
                                {{ $t("auth.descriptions.already_have_account") }}
                                <router-link :to="{ name: 'auth-signin', params: { locale } }" class="kod-link-primary">
                                    {{ $t("auth.actions.sign_in_here") }}
                                </router-link>
                            </p>
                        </v-col>
                    </v-row>
                </div>
            </form>

            <div class="advantages">
                <h2 class="tw-heading-3 tw-mb-12">{{ $t('auth.subTitles.account_advantages') }}</h2>

                <p><b>{{ $t('auth.descriptions.advantage_1') }}</b></p>
                <p>{{ $t('auth.descriptions.advantage_1_message') }}</p>

                <p class="tw-mt-6"><b>{{ $t('auth.descriptions.advantage_2') }}</b></p>
                <p>{{ $t('auth.descriptions.advantage_2_message') }}</p>

                <p class="tw-mt-6"><b>{{ $t('auth.descriptions.advantage_3') }}</b></p>
                <p>{{ $t('auth.descriptions.advantage_3_message') }}</p>

                <p class="tw-mt-6"><b>{{ $t('auth.descriptions.advantage_4') }}</b></p>
                <p>{{ $t('auth.descriptions.advantage_4_message') }}</p>
            </div>
        </div>
    </ka-auth-page>
</template>

<script>
import KaAuthPage from "./ka-auth-page";
import responsiveMixin from "./../responsiveMixin";
import KaSignInWithProviders from "./ka-sign-in-with-providers";
import { getRouteLocale } from "@web/constants/language";

export default {
    mixins: [responsiveMixin],
    components: {
        KaAuthPage,
        KaSignInWithProviders
    },
    data() {
        return {
            email: null,
            password: null,
            submitted: false,
            showPassword: false,
            errorMessage: null
        };
    },
    computed: {
        locale() {
            return getRouteLocale();
        },
        signUpStatus() {
            return this.$store.getters["AUTH/getStatus"]('signUpStatus')
        }
    },
    methods: {
        isValidField(fieldName) {
            switch (fieldName) {
                case "email":
                    return typeof this.email === "string" && /(.+)@(.+){2,}\.(.+){2,}/.test(this.email);
                case "password":
                    return typeof this.password === "string" && this.password.trim().length > 5;
            }
        },

        isFormValid: function() {
            let valid = true;

            if (!this.isValidField("email")) {
                valid = false;

                this.errorMessage = "global.form_rules.not_valid_email";
            } else if (!this.isValidField("password")) {
                valid = false;

                this.errorMessage = "global.form_rules.password_min_six_characters";
            }

            return valid;
        },
        submit() {
            this.submitted = true;

            if (!this.isFormValid()) {
                return;
            }

            this.errorMessage = null;

            this.$store.dispatch('AUTH/signUp', {
                email: this.email,
                password: this.password
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep .v-input__icon .v-icon {
    color: $koderia-grey !important;
}

.form {
    width: 22.5rem;
    display: grid;
    gap: 2rem;
    height: fit-content;

    .form-inputs {
        position: relative;
        margin-top: -1.25rem;
    }

    .form-error-message p {
        margin-top: 0 !important;
    }

    @media screen and (max-width: 499px) {
        width: 100%;
    }
}

.grid {
    display: grid;
    grid-template-columns: fit-content(22.5rem) fit-content(480px);
    gap: 100px;

    .advantages {
        background-color: $koderia-gray-50;
        padding: 40px;

        @media screen and (max-width: 499px) {
            padding: 20px;
        }
    }

    @media screen and (max-width: 959px) {
        margin-top: 70px;
        grid-template-columns: fit-content(22.5rem);
        gap: 50px;
    }

    @media screen and (max-width: 499px) {
        margin-top: 40px;
    }
}
</style>
